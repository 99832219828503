@mixin skeleton-preloader {
    position: relative !important;
    overflow: hidden !important;

    @keyframes load {
        from {
            left: -150px;
        }
        to {
            left: 100%;
        }
    }

    &::before {
        content: '' !important;
        display: block !important;
        position: absolute !important;
        z-index: 9999;
        left: -150px;
        top: 0 !important;
        height: 100% !important;
        width: 150px !important;
        background: linear-gradient(to right, transparent 0%, #D4D4D4 50%, transparent 100%) !important;
        animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) 0s infinite !important;
    }
}

