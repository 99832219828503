.card {
  padding: 24px !important;
  background: #FFFFFF !important;
  box-shadow: none !important;
  border: 1px solid #E0E0E0 !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;

  &--filled {
    background: #FAFAFA !important;
  }

}
