.ng-select {

  &.multiselect {
    padding-bottom: 0 !important;
    height: unset !important;
    border-color: transparent !important;
    border-bottom: 1px solid rgb(235, 236, 240) !important;

    .ng-has-value {
      .ng-placeholder {
          transform: translateY(-2.28125em) scale(0.75) perspective(100px) translateZ(0.001px) !important;
      }

      .ng-clear-wrapper {
          display: none;
      }
    }

    .ng-select-container {
        flex-direction: row !important;
        padding: 0 !important;
        align-items: center !important;
        overflow: visible !important;

          .ng-spinner-loader {
              display: none;
          }

          .ng-arrow-wrapper {
              display: none;
          }

          &.ng-has-value .ng-placeholder {
              visibility: hidden !important;
          }

          &.ng-has-value .ng-placeholder {
              visibility: hidden !important;
          }

          .ng-value-container {
              padding: 0 !important;
              display: flex !important;
              border: none !important;

              .ng-value {
                  line-height: 16px;
                  background-color: var(--gray-background);
                  padding: .8rem 1.2rem;
                  color: var(--black-text);
                  border-radius: 100px;
                  display: flex;
                  flex-direction: row-reverse;

                  .ng-value-icon {
                      margin-left: 1rem;
                      background: rgba(38, 50, 56, 0.5);
                      border-radius: 50%;
                      min-width: 1.6rem;
                      height: 1.6rem;
                      text-align: center;
                      padding: 0;
                  }
              }

              .ng-input {
                display: flex;
                align-items: center;
                padding: 0 !important;
                margin: 0 !important;
                left: 0;
                transform: translate(0%, 0%);

                input {
                  height: 30px;
                  font-size: 16px;
                  line-height: 30px;
                  color: rgba(var(--black-text), .5);
                }
              }

              .ng-placeholder {
                bottom: 7px;
                font-size: 16px !important;
                line-height: 14px !important;
                color: var(--gray-text);
              }
          }

      }
  }

  &.multiselect--focused {
      .ng-select-container {
          flex-direction: row !important;
          padding: 0 !important;
          align-items: center !important;

          .ng-spinner-loader {
              display: block;
          }

          .ng-arrow-wrapper {
              display: none;
          }

          &.ng-has-value .ng-placeholder {
              visibility: hidden !important;
          }

          &.ng-has-value .ng-placeholder {
              visibility: hidden !important;
          }

          .ng-value-container {
              padding: 0 !important;
              display: flex !important;
              border: none !important;

              .ng-value {
                  line-height: 16px;
              }

              .ng-input {
                  padding: 0 !important;
                  left: 0;
              }

              .ng-placeholder {
                  transform: translateY(-2.28125em) scale(0.75) perspective(100px) translateZ(0.001px) !important;
                  color: var(--default);
              }
          }

      }
  }

}

// custom small dropdown
.ng-dropdown-panel.multiselect {
      background-color: unset !important;
      box-shadow: none;
      margin-top: 12px;

      .ng-dropdown-panel-items {
          border: 1px solid #E0E0E0;
          background-color: white;
          border-radius: 4px;

      .ng-option.ng-option-selected {
          color: unset;
          background: #F5F5F5;
      }

      .ng-option {
          align-items: center;
          display: flex;

          &:first-child {
             margin-top: 12px;
          }

          &:last-child {
              margin-bottom: 12px;
          }
      }

      .ng-option-disabled {
          margin: 0 !important;
      }
  }

  .ng-select-bottom {
      top: 12px !important;
  }
}

.ng-select {
  &.multiselect-required {
    .ng-select-container{
      .ng-value-container {
        .ng-placeholder {
          position: relative;
          &::after {
            content: " *" !important;
            color: red;
            font-size: 1.4rem;
            top: -4px;
            position: absolute;
            right: -8px;
          }
        }
      }
    }
  }
}
