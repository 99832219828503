.form {
  &__label {
    font-family: 'Roboto', sans-serif !important;
    font-size: 16px !important;
    line-height: 14px !important;
    letter-spacing: 0.02em !important;
    font-style: normal !important;
    font-weight: normal !important;
    position: relative !important;

    &--small {
      font-size: 13px !important;
      line-height: 30px !important;
      color: #263238 !important;
      opacity: 0.67 !important;
    }

    &--material {
      font-size: 12px;
      line-height: 24px;
      color: #263238 !important;
      opacity: 0.67 !important;
    }

    &--required {
      &:after {
        content: " *" !important;
        color: red;
        font-size: 1.4rem;
        top: -1px;
        position: absolute;
        right: -8px;
      }
    }

    &--type {
      font-size: 10px !important;
      line-height: 12px !important;
      opacity: 0.5 !important;
    }

    &--number {
      font-weight: 500 !important;
      font-size: 20px !important;
      line-height: 23px !important;
    }

    &--name {
      font-size: 16px !important;
      line-height: 18px !important;
    }

    &--gray {
      color: #263238 !important;
      opacity: 0.5 !important;
    }

  }

  &__grid-gap {
    &-6-24 {
      grid-gap: 6px 24px !important;
    }
  }
}
