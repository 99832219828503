.view-data {

  &__colored-data {
    font-family: Roboto, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 2rem;
    color: white;
    padding: .3rem .8rem;
    border-radius: 4px;

    &--gray {
      background: #E0E0E0;
    }

    &--yellow {
      background: #FFC107;
    }

    &--red {
      background: #EF5350;
    }

    &--green {
      background: #4CAF50;
    }

    &--violet {
      background: #4A148C;
    }
  }

    &__data {
        font-size: 16px !important;
        line-height: 24px !important;
        //padding-bottom: .4rem;

        &--clickable {
          border: 3px solid transparent;

          &:hover {
            background-color: #ebecf0;
            cursor: pointer;
            box-sizing: border-box;
            transition: background-color 0.2s ease-in-out 0s, border-color 0.2s ease-in-out 0s;
            overflow-wrap: break-word;
            border-radius: 3px;
          }
        }

        &--medium {
            font-size: 14px !important;
            line-height: 16px !important;
        }

        &--bold {
            font-weight: 500;
        }

        &--label {
            font-size: 10px !important;
            line-height: 12px !important;
            margin-bottom: 6px !important;
        }

        &--gray {
            color: #263238 !important;
            opacity: 0.5 !important;
        }
    }

    &__label-number {
        font-weight: 500;
        font-size: 45px;
        line-height: 40px;
    }

    &__data-col {
        display: flex !important;
        flex-direction: column !important;
    }

    &__items-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    &__item-card {
      font-family: Roboto, serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.02em;
      color: #263238;
      padding: .8rem 1.2rem;
      background: #E0E0E0;
      border-radius: 100px;
      margin: 12px 12px 0 0 !important;
      box-sizing: border-box !important;
    }

    &__image-preview-stub {
        height: 48px;
        min-width: 48px;
        border-radius: 50%;
        background: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;

        &--blue {
            background: #4867AA;
        }

        &--red {
            background: #FA001B;
        }

        &--black {
            background: #323232;
        }

        &--instagram {
            background: #f09433;
            background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
            background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
            background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
        }
    }
}
