$mdc-layout-grid-breakpoints: (
  largedesktop: 1800px,
  desktop: 840px,
  tablet: 600px,
  phone: 0,
);

$mdc-layout-grid-columns: (
  largedesktop: 12,
  desktop: 12,
  tablet: 8,
  phone: 4,
);

$mdc-layout-grid-default-margin: (
  largedesktop: 24px,
  desktop: 24px,
  tablet: 16px,
  phone: 16px,
) !default;


$mdc-layout-grid-default-gutter: (
  largedesktop: 24px,
  desktop: 24px,
  tablet: 16px,
  phone: 16px,
) !default;

$mdc-layout-grid-column-width: (
  largedesktop: 72px,
  desktop: 72px,
  tablet: 72px,
  phone: 72px,
) !default;

@import "~@material/layout-grid/mdc-layout-grid";

@import '~@ng-select/ng-select/themes/material.theme.css';
@import "~@ng-select/ng-select/scss/material.theme";

@import './assets/scss/material-classes';
@import './assets/scss/forms';
@import './assets/scss/lists';
@import './assets/scss/view-page';

@import './assets/scss/components/text-link';
@import './assets/scss/components/btn';
@import './assets/scss/components/card';
@import './assets/scss/components/shoot-list';
@import './assets/scss/components/organizer';
@import './assets/scss/components/photo-preview';
@import './assets/scss/components/tab';
@import './assets/scss/components/sidebar';
@import './assets/scss/components/multiselect';
@import './assets/scss/components/list';
@import './assets/scss/components/overlay-preloader';
@import './assets/scss/components/related-lead';
@import './assets/scss/components/photo-icon';
@import './assets/scss/components/shoot';
@import './assets/scss/components/task-doard';
@import './assets/scss/components/time-table';
@import 'assets/scss/components/items-container';

@import './assets/scss/mixins/skeleton-before-preloader';
@import './assets/scss/ng-select/ng-select.scss';
@import './assets/scss/base/base';
@import './assets/scss/pages/not-found';
@import './assets/scss/modal-containers';

:root {
  --primary: #4A148C;
  --gray: #ebecf0;
  --gray-text: rgba(38, 50, 56, 0.5);
  --gray-background: #E0E0E0;
  --default: rgba(0, 0, 0, 0.87);
  --black-text: #263238;
  --ck-border-radius: 4px;
}

html, body {
  height: 100%;
  margin: 0;
}

.ng-select .ng-select-container {
  cursor: default;
  display: flex;
  flex-direction: row-reverse;
  outline: none;
  overflow: hidden;
  position: relative;
  width: 100%;
  min-height: 36px !important;
}

.ng-select .ng-select-container:after {
  border-bottom: none;
  content: '';
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  transition: border-color 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
}

.ng-value-container {
  display: none !important;
}
