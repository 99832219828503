.photo-preview {
  min-width: 140px !important;
  width: 140px !important;
  height: 176px !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  text-align: center !important;
  background: rgba(74, 20, 140, 0.1) !important;
  border: 2px dashed #4A148C !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  cursor: pointer !important;

  &--loading {
    filter: blur(2px);
    pointer-events: none;
  }

  &--no-border {
      border: none !important;
      align-items: flex-start !important;
      justify-content: start !important;

  }

  &__remove-container {
      justify-content: center !important;
      width:140px !important;
      background: #000000 !important;
      opacity: 0.8 !important;
      box-sizing: border-box !important;
  }

  &__remove-text {
      font-size: 12px !important;
      line-height: 24px !important;
  }

  &--table {
    width: 3.6rem;
    border-radius: 50%;
    height: 3.6rem;
  }

  &__icon-container {
    padding: 1rem;
    width: 3.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3.6rem;
    background: rgba(#4A148C, .1);
    border-radius: 50px;
  }

  &__sidebar {
    color: #4A148C !important;
    width: 4.8rem !important;
    height: 4.8rem !important;
    border-radius: 50%;
    object-fit: cover;
    object-position: top;
  }

  &__icon {
    color: #4A148C !important;
    width: 30px !important;
    font-size: 30px !important;
    height: 30px !important;
  }

  &__img {
    border-radius: 4px 4px 0 0 !important;
    width: 140px !important;
    height: 140px !important;
  }

  &__label {
    font-size: 10px !important;
    line-height: 12px !important;
    text-align: center !important;
    color: #4A148C !important;
  }
}

.spinner-container {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  z-index: 1 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.button-container {
  display: inline-block !important;
  position: relative !important;
}

.photo-loader {
  width: 480px !important;
  height: 360px !important;

  &--stub {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    // background: rgba(74, 20, 140, 0.1) !important;
    // border: 2px dashed #4A148C !important;
    // box-sizing: border-box !important;
    // border-radius: 4px !important;
  }

  &__text {
    font-size: 16px !important;
    line-height: 19px !important;
    text-align: center !important;
    color: gray !important;
  }
}

.cropper-modal {
    opacity: .8 !important;
}
