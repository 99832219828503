// dialog containers
.custom-dialog-container .mat-dialog-container {
  padding: 0 !important;
  width: 66rem !important;
  overflow: initial !important;
  border-radius: 8px !important;
}

.lead-shoot-record-dialog-container .mat-dialog-container {
  height: 48rem !important;
}

.related-lead-rules .mat-dialog-container {
  width: 70rem !important;
}

.control-task-reject .mat-dialog-container {
  width: 55rem !important;
}

.default-form-modal .mat-dialog-container {
  width: 55rem !important;
}

.payment-form-modal .mat-dialog-container {
  width: 55rem !important;
  height: 75rem;
}

.sidebar-modal-container .mat-dialog-container {
  position: absolute !important;
  padding: 0 !important;
  border-radius: 0;
  box-sizing: border-box;
  top: 0 !important;
  left: 60% !important;

  @media (max-width: 839px) {
    left: 0 !important;
  }
}

.sidebar-filters-modal-container .mat-dialog-container {
  position: absolute !important;
  padding: 0 !important;
  border-radius: 0;
  box-sizing: border-box;
  top: 0 !important;
  left: 70% !important;

  @media (max-width: 839px) {
    left: 0 !important;
  }
}

.related-lead-sidebar-modal-container .mat-dialog-container {
  position: absolute !important;
  padding: 0 !important;
  border-radius: 0;
  box-sizing: border-box;
  top: 0 !important;
  left: 60% !important;

  @media (max-width: 839px) {
    left: 0 !important;
  }
}

.ck-editor__editable_inline {
  min-height: 500px;
}

.default-scrollable-form-modal .mat-dialog-container {
  width: 70rem;
  height: 55rem;
}

.invoice-regroup-form-modal .mat-dialog-container {
  width: 70rem;
  height: 75rem;
}

.user-absence-form-modal .mat-dialog-container {
  width: 60rem;
}

.lead-project-assign-form-modal ,mat-dialog-container {
  width: 43rem;
}
