.related-lead {

    &__name {
        font-size: 24px !important;
        line-height: 28px !important;
        letter-spacing: 0.02em !important;
    }

    &__label {
        font-size: 24px !important;
        line-height: 28px !important;
        color: rgba(0, 0, 0, 0.87) !important;
    }

    &__photo {
        min-width: 40px !important;
        height: 40px !important;
        background-color: #4A148C !important;
        border-radius: 50% !important;
        color: white !important;
        line-height: 40px !important;
        text-align: center !important;
        object-fit: cover;
        object-position: top;
    }

    &__divider {
        border-bottom: 1px solid #E0E0E0 !important;
    }

    &__relation-type-label {
        font-size: 16px;
        line-height: 20px;
        color: rgba(38, 50, 56, 0.87);
    }

    &__contacts {
        display: flex;
        flex-wrap: wrap;
    }

    &__lead-contact-box {
        border: 1px solid #E0E0E0 !important;
        box-sizing: border-box !important;
        border-radius: 8px !important;
        padding: 8px !important;

        line-height: 18px !important;
        font-size: 16px !important;

        margin-right: 8px !important;
        margin-top: 8px !important;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    &__lead-contact-relation-type {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 12px;
        text-transform: uppercase;
        margin-bottom: .4rem;
        color: rgba(0, 0, 0, 0.87);
    }

    &__row {
        display: flex !important;
        align-items: center !important;
    }

    &__info-icon {
        height: 2rem !important;
        width: 2rem !important;
    }
}
