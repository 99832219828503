.not-found {

    &__container {
        width: 100% !important;
        height: 80vh !important;
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
    }

    &__background-image-container {
        background-image: url('../../images/404-background.svg') !important;
        width: 761px !important;
        height:420px !important;
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
    }

    &__text {
        font-weight: bold !important;
        font-size: 34px !important;
        text-align: center !important;
        line-height: 120% !important;
        letter-spacing: 0.02em !important;
        color: #263238 !important;
    }

    &__logo {
        width: 426px !important;
        height: 240px !important;
    }


}