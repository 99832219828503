@import '~@angular/material/theming';

@include mat-core();

$app-primary: mat-palette($mat-purple, 900);
$app-accent: mat-palette($mat-pink, A200, A100, A400);
$app-warn: mat-palette($mat-red);
$dark-primary-text: #263238;

$app-theme: mat-light-theme((
  color: (
    primary: $app-primary,
    accent: $app-accent,
    warn: $app-warn,
  )
));

$custom-typography: mat-typography-config(
  $font-family: 'Roboto, monospace',
  $headline: mat-typography-level(30px, 46px, 500),
  $input:    mat-typography-level(16px, 1.125, 400)
);

@include angular-material-theme($app-theme);
@include angular-material-typography($custom-typography);

.mat-stroked-button {
  border-color: var(--primary) !important;
}