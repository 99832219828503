@import './typography/headings';

@import './gutters';
@import './typography';
@import './helpers';
@import './layout';
@import './mobile-layout';

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
    font-size: 62.5%; // 1rem = 10px, 10px/16px = 62.5%
}
