.mobile {
  &-column {
    @media (max-width: 840px) {
      display: flex;
      flex-direction: column;
    }
  }

  &-column-reverse {
    @media (max-width: 840px) {
      display: flex;
      flex-direction: column-reverse;
    }
  }
}
