.mat-icon {
    vertical-align: middle;
}

.mat-form-field {
    width: 100%;

    .mat-form-field-label {
        color: rgba(38, 50, 56, 0.5);
    }

    .mat-form-field-underline {
        background-color: #E0E0E0;
    }
}

  .mat-dialog-container {
    background: white;
    color: black;
}

.mat-checkbox-frame {
    border-color: rgba(0, 0, 0, 0.54);
}

.mat-header-cell {
    color: black;
    opacity: .5;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: var(--primary);
}

.mat-checkbox-checkmark-path {
    stroke: white !important;
}

.mat-dialog-container {
  position: relative;
}

.toast-container {
    border-radius: 22px !important;
    margin-bottom: 2px !important;
  }

  .toast-container .toast {
    margin: 2px !important;
    box-shadow: none !important;
    border-radius: 20px !important;
  }

  .toast-success{}

  .toast-title{}

  .toast-message{}


.mat-horizontal-content-container {
    padding: 0 !important;
}

//mat-step

// .mat-step-header:hover{
//     background-color: white !important;
// }
.mat-step-header.cdk-program-focused {
    background-color: transparent;
}
// .mat-step-header.cdk-mouse-focused{
//     background-color: white;
// }
.mat-step-header.cdk-focused{
    background-color: transparent;
}

.mat-step-icon {
    height: 36px !important;
    width: 36px !important;
}

.mat-horizontal-stepper-header {
    &:first-child {
        padding-left: 1rem !important;
    }

    &:last-child {
        padding-right: 1rem !important;
    }
}

.mat-step-header .mat-step-icon-state-done {
    background-color: #4a148c !important;
    color: white;
}

.mat-step-header .mat-step-icon-state-edit {
    background-color: #4a148c !important;
    color: white;
}

.mat-step-header .mat-step-icon-selected {
    background-color: #4a148c !important;
    color: white;
}

.mat-step-header .mat-step-icon {
    background-color: rgba(74, 20, 140, .1);
}

.mat-stepper-horizontal-line {
    border-top-width: 1px;
    border-top-style: solid;
    flex: auto;
    height: 0;
    margin: 0 !important;
}

.mat-step-label {
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    white-space: initial !important;
    color: #263238 !important;
    // max-width: 90px !important;
}

.mat-step-header {
    pointer-events: none !important;
}

.mat-horizontal-stepper-header-container {
    white-space: break-spaces !important;
}

.mat-step-text-label {
    display: block !important;
    overflow-wrap: break-word !important;
    -ms-word-break: break-all;
    word-break: break-all;
   word-break: break-word;
    -webkit-hyphens: auto;
       -moz-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
}


// end step

.ck-editor__editable {
    min-height: 200px !important;
}

.mat-drawer-inner-container {
    overflow: hidden !important;
}
// Mat tab
.mat-tab-label {
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    opacity: 0.5;
    color: #263238;
}

.mat-tab-body-wrapper {
    overflow: visible !important;
}

.mat-tab-body {
    overflow: visible !important;
}

.mat-tab-body-content {
    overflow: visible !important;
}

.mat-tab-nav-bar, .mat-tab-header {
    border-bottom: none;
}

.mat-tab-label.mat-tab-label-active {
    opacity: 1;
    border: 1px solid #E0E0E0;
    border-radius: 4px 4px 0px 0px;
    border-bottom: none;
    padding: 1.2rem 2.4rem;
}

.mat-ink-bar {
    display: none;
}

.mat-step-header .mat-step-header-ripple {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    pointer-events: none;
    display: none !important;
}


// Buttons

.mat-stroked-button.mat-button-disabled.mat-button-disabled {
    border-color: rgba(0, 0, 0, 0.26) !important;
}
