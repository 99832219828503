.heading {
  font-family: Roboto, sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 120% !important;
  letter-spacing: 0.02em !important;
  position: relative;

  &--page {
    font-size: 28px !important;
    line-height: 120% !important;
    letter-spacing: 0.02em !important;
  }

  &--section {
    font-size: 20px !important;
    line-height: 23px !important;
  }

  &--required {
    &::after {
      content: ' *' !important;
      color: red;
      font-size: 1.2rem;
      top: -1px;
      position: absolute;
      right: -12px;
    }
  }
}

.page-heading {
  font-weight: 500 !important;
  font-size: 26px !important;
  line-height: 120% !important;

  @media (max-width: 839px) and (min-width: 600px) {
    font-size: 24px !important;
  }

  @media (max-width: 599px) {
    font-size: 18px !important;
  }
}

.link-text-color {
  color: var(--dark-primary-text);
}

// Text alignment
.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

// Weight
.font-weight-medium {
  font-weight: 500;
}

.font-weight-bold {
  font-weight: 700;
}

// Color
.color-primary {
  color: var(--primary) !important;
}

.color-default {
  color: var(--default) !important;
}

.color-white {
  color: #fff !important;
}

.color-red {
  color: #E53935 !important;
}

.color-orange {
  color: orange !important;
}

.color-blue {
  color: blue !important;
}

.color-purple-light {
  color: #9585ae !important;
}

.color-green {
  color: #4CAF50;
}

.color-gray {
  color: rgba(0, 0, 0, 0.47) !important;
}

.color-light-gray {
  color: rgba(#263238, 0.5) !important;
}

// Transform
.text-uppercase {
  text-transform: uppercase;
}

.text-underlined {
  text-decoration: underline;
}

