.table {
  border: 1px solid #E0E0E0 !important;
  border-bottom: none !important;
  box-sizing: border-box !important;
  border-radius: 3px 3px 0 0 !important;

  &--without-paginator {
      border-bottom: 1px solid #E0E0E0 !important;
  }

  &__data-container {
      height: calc(100vh - 29.5rem) !important; // 72 + 24 + 40 + 12 + 60 + 56.5 + 70 =
      overflow-y: auto !important;

      &--without-paginator {
          overflow: visible !important;
          height: 40rem !important;
      }
  }


  &__first-cell {
    width: 6.4rem;
  }

  &__id-cell {
    width: 4rem;
  }

  &__photo-cell {
    width: 5.6rem !important;
  }

  &__last-cell {
    display: flex;
    padding: 4px;
    align-items: center;
    height: 4.8rem;
    justify-content: flex-end;
  }

  &__action-cell {
    width: 4.8rem;
  }

  &__title-cell {
    width: 30%;
  }

    &__mat-table {
      background: white !important;
      width: 100% !important;
    }

    &__row-photo {
        border-radius: 50px !important;
        height:36px !important;
        min-width: 36px !important;
        object-fit: cover !important;
        object-position: top !important;
    }

    &__mat-header-cell {
        padding-top: 12px !important;
        padding-bottom: 12px !important;
        opacity: 1 !important;
        font-size: 14px !important;
        line-height: 18px !important;
        color: rgba(38, 50, 56, .5) !important;
        text-align: left !important;
    }

    &__label {
        &--no-data {
            padding: 24px !important;
            width:100% !important;
            font-style: normal !important;
            font-weight: normal !important;
            font-size: 16px !important;
            line-height: 18px !important;
            text-align: center !important;
            color: #263238 !important;
            opacity: 0.5 !important;
            height: 80% !important;
            display: flex !important;
            justify-content: center !important;
            box-sizing: border-box !important;
            align-items: center !important;
        }
    }

    @media (max-width: 2000px) {
      &__data-container {
        overflow-y: auto !important;

        &--without-paginator {
            overflow: visible !important;
            height: 40rem !important;
        }
      }
    }

  @media (max-width: 850px) {
    &__data-container {
      height: calc(100vh - 29rem) !important;
    }
  }

}

.mat-row:nth-child(odd){
    background-color:#FAFAFA;
}

table {
    width: 100%;
    table-layout: fixed;
}

th, td {
    //overflow: hidden;
    //width:auto;
    //text-overflow: ellipsis;
    //white-space: nowrap;
}

td.mat-cell {
    border: none !important;
    line-height: 18px !important;
    padding-right: 1rem !important;
}

.mat-checkbox-frame {
    border-color: rgba(38, 50, 56, 0.5) !important;
}

.mat-cell, .mat-header-cell, .mat-footer-cell {
    color: black;
}
