.list-container {
    height: 312px !important;
    width: 408px !important;

    display: flex !important;
    flex-direction: column !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    border: 1px solid #E0E0E0 !important;
    box-sizing: border-box !important;
    border-radius: 4px !important;

    &__item {
        font-size: 13px !important;
        line-height: 48px !important;
        height: 48px !important;
        display: flex !important;
        align-items: center !important;
        padding: 0 24px !important;

        &:nth-child(odd) {
            background: #FAFAFA !important;
        }

        &--active {
            background: rgba(74, 20, 140, 0.1) !important;
            color: #4A148C !important;
        }
    }
}

.controls-margin {
    margin-bottom: 12px !important;

    @media (max-width: 839px) and (min-width: 600px) {
        margin-bottom: 25px !important;
      }
  
      @media (max-width: 599px) {
        margin-bottom: 10px !important;
      }
}