.text-link {
  font-family: Roboto, serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 18px !important;
  text-decoration-line: underline !important;
  color: #4A148C !important;
  cursor: pointer !important;

  &--medium {
    font-size: 16px !important;
  }

  &:focus {
    outline: 0;
  }
}

.div-link:focus {
  outline: 0;
  cursor: pointer;
}
