.sidebar-container {
    z-index: 1 !important;
    height: 100vh !important;
    width: 40vw !important;
    position: relative !important;
    background-color: white !important;

    &--filters {
        width: 30vw !important;
    }

    &__header {
        height: 72px !important;
        background-color: var(--primary) !important;
    }

    &__contact {
        border-bottom: 1px solid #E0E0E0 !important;
    }

    &__heading {
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 20px !important;
        line-height: 23px !important;
        letter-spacing: 0.02em !important;

        &--white {
            color: white !important;
        }
    }

    &__number {
        font-style: normal !important;
        font-weight: normal !important;
        font-size: 16px !important;
        line-height: 18px !important;
    }

  &-body {
    height: calc(100vh - 7.2rem);
    display: flex;
    flex-direction: column;
    width: 40%;

    @media (max-width: 839px) {
      width: 100%;
    }
  }

}
