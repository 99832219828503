.tab {

    &__container {
        width: 100% !important;
        border: 1px solid #263238 !important;
        box-sizing: border-box !important;
        border-radius: 4px !important;
        display: flex;
    }

    &__item {
        width: 50% !important;
        font-size: 14px !important;
        line-height: 24px !important;
        text-align: center !important;
        letter-spacing: 0.02em !important;
        color: #263238 !important;
        cursor: pointer !important;

        &--active {
            color: #4A148C !important;
        }

        &:not(:last-child) {
            border-right: 1px solid #263238 !important;
        }
    }
}