.organizer-pane {
  border-top: 1px solid #E0E0E0 !important;
  border-bottom: 1px solid #E0E0E0 !important;
  width: calc(100% + 46px) !important;
  transform: translateX(-24px) !important;
  display: flex !important;
  overflow-x: hidden !important;

  &__icon {
    margin-top: 8px !important;
  }

  &__tabs {
    width: 100%;
    border: 1px solid #4A148C;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
  }

  &__tab {
    text-align: center;
    font-size: 14px;
    line-height: 24px;
    width: 33%;
    cursor: pointer;
    padding-top: 3px;
    padding-bottom: 3px;

    &--active {
      color: #4A148C;
    }

    &:focus {
      outline: none;
    }

    &:not(:last-child) {
      border-right: 1px solid #4A148C;
    }
  }

  &__item {
    padding: 12px !important;
    display: flex !important;
    justify-content: space-between !important;

    &--select-day {
      min-width: 45rem;
      border-right: 1px solid #E0E0E0 !important;
    }

    &--select-month {
      min-width: 17.6rem;
      border-right: 1px solid #E0E0E0 !important;
      width: auto !important;
    }

    &--select-year {
      border-right: 1px solid #E0E0E0 !important;
      width: auto !important;
    }

    &--view-type {}

  }

  &__date-container {
    display: flex !important;
  }

  &__month-day-container {
    display: flex !important;
    width: 100% !important;
  }

  &__days-container {
    display: flex !important;
    padding: 0 12px !important;
  }

  &__col {
    display: flex !important;
    flex-direction: column !important;
  }

  &__label {
    font-size: 10px !important;
    line-height: 12px !important;
    text-align: center !important;
    transform: translateX(-.5%);
    color: #263238 !important;
    opacity: 0.5 !important;
  }

  &__value {
    height: 24px !important;
    text-align: center !important;
    font-size: 16px !important;
    line-height: 24px !important;
    margin-top: 6px !important;
    cursor: pointer !important;
  }

  &__day {
    display: flex !important;
    flex-direction: column !important;

    &:not(:last-child) {
      margin-right: 24px !important;
    }

    &-of-week {
      font-size: 10px !important;
      line-height: 12px !important;
      text-align: center !important;
      color: #263238 !important;
      opacity: 0.5 !important;
    }

    &-of-month {
      width: 24px !important;
      height: 24px !important;
      text-align: center !important;
      font-size: 16px !important;
      line-height: 24px !important;
      margin-top: 6px !important;
      cursor: pointer !important;

      &--selected {
        background: #4A148C !important;
        border-radius: 4px !important;
        color: white !important;
      }
    }
  }


    @media (max-width: 1375px) {
        flex-direction: column !important;
        width: calc(100% + 50px) !important;

        &__date-container {
            width: 100% !important;
        }


        &__month-day-container {
            width: 100% !important;
        }

        &__item {
            &--view-type {
                border-top: 1px solid #E0E0E0 !important;
            }

            &--select-month {
                //justify-content: center !important;
                width: 50% !important;
            }

            &--select-year {
                //justify-content: center !important;
                width: 50% !important;
            }

        }
    }

    @media (max-width: 1025px) {
      flex-direction: column !important;

      &__item {
        &--select-day {
          width: 100% !important;
          justify-content: center !important;
        }
      }

      &__date-container {
        display: flex !important;
        flex-direction: column !important;
      }

      &__month-day-container {
        border-top: 1px solid #E0E0E0 !important;
      }
    }

    // @media (max-width: 599px) {

    // }

}

.step {
  height: 200px !important;
}
