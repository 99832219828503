.overflow-hidden {
    overflow: hidden !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-auto {
  overflow: auto !important;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.overflow-scroll {
  overflow: scroll !important;
}
// Floats
.pull-right {
    float: right !important;
}

.pull-left {
    float: left !important;
}

.position-relative {
    position: relative !important;
}

// Background
.background-white {
    background-color: #ffffff !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.block {
    display: block !important;
}

.inline {
  display: inline !important;
}

.loading-box-state {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.overlay {
    position: fixed !important;
    left: 0 !important;
    top: 0 !important;
    z-index: 1000 !important;
    width: 100% !important;
    height: 100% !important;
    background-color: rgba(0, 0, 0, 0.5) !important;
}

.w-25 {
    width: 25% !important;
}

.w-50 {
    width: 50% !important;
}

.w-100 {
    width: 100% !important;
}

.h-25 {
    height: 25% !important;
}

.h-50 {
    height: 50% !important;
}

.h-85 {
  height: 85% !important;
}

.h-100 {
    height: 100% !important;
}

.border {
    &-full {
        border: 1px solid #E0E0E0 !important;
    }

    &-left {
        border-left: 1px solid #E0E0E0 !important;
    }

    &-top {
        border-top: 1px solid #E0E0E0 !important;
    }

    &-right {
        border-right: 1px solid #E0E0E0 !important;
    }

    &-bottom {
        border-bottom: 1px solid #E0E0E0 !important;
    }
}

.disable-hover-background {
  &:hover {
    background: transparent !important;
  }
}

.absolute-center {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
