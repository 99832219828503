@import '../mixins/skeleton-before-preloader';

.shoot-list {
    display: flex !important;
    flex-direction: column !important;
    width: 100% !important;
    height: 73.6vh !important;
    box-sizing: border-box !important;
    overflow-y: auto !important;

    &--skeleton {
      overflow: hidden !important;
    }

    &::-webkit-scrollbar {
        width: 18px !important;
        height: 18px !important;
    }
    &::-webkit-scrollbar-thumb {
        height: 6px !important;
        border-right: 6px solid rgba(0, 0, 0, 0) !important;
        border-left: 6px solid rgba(0, 0, 0, 0) !important;

        background-clip: padding-box !important;
        -webkit-border-radius: 7px !important;
        background-color: rgba(0, 0, 0, 0.15) !important;
    }

    &__card {
        padding: 12px 24px !important;
        background: #FFFFFF !important;
        border: 1px solid #E0E0E0 !important;
        box-sizing: border-box !important;
        overflow-y: visible !important;
        border-radius: 4px !important;
        display: flex !important;
        width: 100% !important;
        margin-bottom: 1px !important;
        cursor: pointer !important;

        &:hover {
            background-color: #f0eaea !important;
        }
        transition: 1s;

        &:not(:last-child) {
            margin-bottom: 24px !important;
        }

        &--skeleton {
            cursor: default !important;
            background: #D4D4D4 !important;
            @include skeleton-preloader;

            overflow: unset !important;
        }
    }

    &__card-part {
        width: 50% !important;

        &:not(:last-child) {
            padding-right: 24px !important;
            border-right: 1px solid #E0E0E0 !important;
        }

        &:not(:first-child) {
            padding-left: 24px !important;
        }
    }

    &__card-status {
        &-green {
            border-right: 8px solid #59BC5E !important;
        }
        &-orange {
            border-right: 8px solid #FFBF60 !important;
        }
    }

    &__skeleton-heading {
        width: 100%;
        height: 16px;
        border-radius: 4px;
        background: #BEBEBE !important;
    }

    &__skeleton-text-label {
        width: 20%;
        height: 12px;
        border-radius: 4px;
        background: #BEBEBE !important;
    }

    &__skeleton-text {
        width: 35%;
        height: 16px;
        border-radius: 4px;
        background: #BEBEBE !important;
    }

  @media (max-width: 1375px) {
    height: 66.2vh !important;
  }

  @media (max-width: 1025px) {
    height: 58.8vh !important;
  }
}
