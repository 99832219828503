.flex {
  &-row {
    display: flex !important;
    flex-direction: row !important;
  }

  &-column {
    display: flex !important;
    flex-direction: column !important;
  }

  &__align {
    &-center {
      align-items: center !important;
    }

    &-start {
      align-items: flex-start;
    }

    &-end {
      align-items: flex-end;
    }

    &-baseline {
      align-items: baseline !important;
    }
  }

  &__justify {
    &-center {
      justify-content: center !important;
    }

    &-space-between {
      justify-content: space-between !important;
    }

    &-end {
      justify-content: flex-end !important;
    }
  }
}

.page-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 7.2rem);

  &-view {
    height: calc(100vh - 7.2rem);
    padding: 2.4rem;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.page-footer {
  border-top: 1px solid var(--gray-background);
  width: calc(100% + 4.8rem);
  margin-left: -2.4rem;
  min-height: 4.8rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 2.4rem;
}

.page-content {
  height: calc(100vh - 7.2rem);
}

.sticky-page-footer {
  background: white;
  z-index: 200;
  position: absolute;
  bottom: 0;
  border-top: 1px solid var(--gray-background);
  width: 100%;
  min-height: 4.8rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 2.4rem;
  padding-left: 2.4rem;
}
