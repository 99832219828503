.overlay-preloader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, .3);
  mix-blend-mode: normal;
  backdrop-filter: blur(10px);
  z-index: 1000;

  display: flex;
  align-items: center;
  justify-content: center;

  &__preloader {
      height: 60px;
      width: 60px;

      -webkit-animation: spin 1s linear infinite; /* Safari */
      animation: spin 1s linear infinite;

  }

}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}