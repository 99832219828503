.btn {
  font-weight: 500 !important;
  font-size: 1.4rem !important;
  line-height: 3.2rem !important;
  height: 3.2rem;
  letter-spacing: 0.02em !important;
  text-align: center;

  &--thin {
    font-weight: 400 !important;
  }

  &--uppercase {
    text-transform: uppercase !important;
  }

  &--border-transparent {
    border: 1px solid transparent !important;
  }

  &--small {
    font-size: 10px !important;
    line-height: 12px !important;
    margin-bottom: 6px !important;
    padding: 6px !important;
  }

  &--red {
    color: red !important;
    border-color: red !important;
  }

  &--red-no-border {
    color: red !important;
  }

  &--icon {
    height: 24px !important;
    width: 24px !important;
    line-height: 24px !important;
    font-size: 24px;
  }

  &--icon-abs {
    position: absolute !important;
    top: 27px !important;
    right: 27px !important;
    height: 15px !important;
    width: 15px !important;
    line-height: 15px !important;
  }

  &--related-items {
    position: absolute !important;
    top: 4px !important;
    right: 4px !important;
    height: 17px !important;
    width: 17px !important;
    font-size: 10px;
    line-height: 17px !important;
  }

  &--link {
    font-size: 16px !important;
    line-height: 18px !important;
    text-decoration-line: underline !important;
    color: #4A148C !important;
  }

  &--organizer {
    margin-right: 24px !important;
  }

  @media (max-width: 839px) and (min-width: 600px) {
    font-size: 12px !important;
    line-height: 30px !important;
    height: 30px !important;
    width: auto !important;
    min-width: auto !important;

    &--icon {
      height: 20px !important;
      width: 20px !important;
    }
  }

  @media (max-width: 599px) {
    font-size: 10px !important;
    line-height: 26px !important;
    height: 26px !important;
    width: auto !important;
    min-width: auto !important;

    &--icon {
      height: 18px !important;
      width: 18px !important;
    }

    &--organizer {
      margin-bottom: 10px !important;
      margin-right: 0px !important;
    }
  }
}
