.multiselect {
    display: flex !important;
    flex-wrap: wrap !important;

    &__item {
        font-size: 10px !important;
        line-height: 12px !important;
        letter-spacing: 0.02em !important;
        color: #263238 !important;

        margin-bottom: 12px !important;

        display: flex !important;
        padding: 6px !important;
        border: 1px solid #263238 !important;
        box-sizing: border-box !important;
        border-radius: 4px !important;
        &:not(:last-child) {
            margin-right: 12px !important;
        }
    }

    &__item-icon {
        height: 12px !important;
        width: 12px !important;
        margin-left: 12px !important;
    }
}