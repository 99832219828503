@import './multiselect';

.ng-select {
  border: 1px solid #E0E0E0 !important;
  border-radius: 4px !important;
  padding-bottom: 4px !important;
  height: 36px !important;

  &.ng-select-disabled {}

  &.rounded-right {}

  &.ng-select-opened {}

  .ng-arrow-wrapper {
    top: unset !important;
    align-self: center !important;
    display: flex;
    justify-content: center;

    .ng-arrow {
    }
  }

  &.ng-select-single {
    &.custom {
      .ng-select-container {
        height: 42px !important;
        .ng-arrow-wrapper {
          align-self: unset !important;
          bottom: unset !important;
        }
      }
    }

    &.custom-medium {
      .ng-select-container {
        height: 36px !important;
      }
    }

    &.custom-small {
      .ng-select-container {
        height: 36px !important;
      }
    }
  }


  &.ng-select-multiple {
    &.custom {
      .ng-select-container {
        .ng-value-container {
          .ng-value {
            .ng-value-icon {}
          }

          :last-child.ng-value {}
        }
      }
    }
  }

  .ng-select-single {
    .ng-select-container {
      .ng-arrow-wrapper {
        align-self: unset !important;
        bottom: unset !important;
      }
    }
  }

  // Custom 1
  &.custom {

    &.filter-select {
      .ng-arrow-wrapper {}
    }

    .ng-select-container {
      flex-direction: row !important;
      padding: 0 12px !important;
      padding-bottom: 4px !important;
      align-items: center !important;

      &.ng-has-value .ng-placeholder {
        visibility: hidden !important;
      }

      .ng-value-container {
        padding: 0 !important;
        display: flex !important;
        border: none !important;

        .ng-value {
          line-height: 16px;
        }

        .ng-input {
          padding: 0 4px !important;
        }

        .ng-placeholder {
          color: var(--default);
        }
      }

    }
  }

  // Editable field
  &.custom-small {
    border-color: transparent !important;

    &:hover {
      background-color: rgb(235, 236, 240);
    }

    &.filter-select {}

    .ng-has-value {
      .ng-clear-wrapper {
        display: none;
      }
    }

    .ng-select-container {
      flex-direction: row !important;
      padding: 0 6px !important;
      align-items: center !important;

      .ng-spinner-loader {
        display: none;
      }

      .ng-arrow-wrapper {
        display: none;
      }

      &.ng-has-value .ng-placeholder {
        visibility: hidden !important;
      }

      &.ng-has-value .ng-placeholder {
        visibility: hidden !important;
      }

      .ng-value-container {
        padding: 0 !important;
        display: flex !important;
        border: none !important;

        .ng-value {
          line-height: 16px;
          width: 100%;
        }

        .ng-input {
          padding: 0 4px !important;
          left: 6px;
          transform: translate(0%, -15%);
        }

        .ng-placeholder {
          color: var(--default);
        }
      }

    }
  }

  &.custom-small--focused {
    border: 1px solid #E0E0E0 !important;
    &.filter-select {}

    .ng-has-value {
      .ng-clear-wrapper {
        display: block !important;
      }
    }

    .ng-select-container {
      flex-direction: row !important;
      padding: 0 6px !important;
      align-items: center !important;

      .ng-spinner-loader {
        display: block;
      }

      .ng-arrow-wrapper {
        display: none;
      }

      &.ng-has-value .ng-placeholder {
        visibility: hidden !important;
      }

      &.ng-has-value .ng-placeholder {
        visibility: hidden !important;
      }

      .ng-value-container {
        padding: 0 !important;
        display: flex !important;
        border: none !important;

        .ng-value {
          line-height: 16px;
        }

        .ng-input {
          padding: 0 4px !important;
          left: 6px;
          transform: translate(0%, -15%);
        }

        .ng-placeholder {
          color: var(--default);
        }
      }

    }
  }


  // Custom gray
  &.custom-gray {
    &.filter-select {}

    .ng-select-container {
      flex-direction: row !important;
      align-items: center !important;

      .ng-arrow-wrapper {
        display: none;
      }

      &.ng-has-value .ng-placeholder {
        visibility: hidden !important;
      }

      .ng-value-container {
        padding: 0 !important;
        display: flex !important;
        border: none !important;

        .ng-value {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          line-height: 16px;
          background: #E0E0E0;
          border-radius: 4px;
          padding: 4px 8px;
        }

        .ng-input {
          padding: 0 4px !important;
        }

        .ng-placeholder {
          color: var(--default);
        }
      }

    }
  }
}

// custom small dropdown
.ng-dropdown-panel.custom-small {
  box-shadow: none;
  margin-top: 12px;

  .ng-dropdown-panel-items {
    border: 1px solid #E0E0E0;
    border-radius: 4px;

    .ng-option.ng-option-selected {
      color: unset;
      background: #F5F5F5;
    }

    .ng-option {
      align-items: center;
      display: flex;

      &:first-child {
        margin-top: 12px;
      }

      &:last-child {
        margin-bottom: 12px;
      }
    }

    .ng-option-disabled {
      margin: 0 !important;
    }
  }
  .ng-select-bottom {
    top: 12px !important;
  }
}

// Custom gray dropdown
.ng-dropdown-panel.custom-gray {
  box-shadow: none;
  margin-top: 12px;

  .ng-dropdown-panel-items {
    background: white;
    padding: 12px 0;
    border: 1px solid #E0E0E0;
    border-radius: 4px;

    .ng-option.ng-option-selected {
      color: unset;
      background: #F5F5F5;
    }

    .ng-option {
      .ng-option-label {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #263238;
        padding: 4px 8px;
        background: #E0E0E0;
        border-radius: 4px;
      }
    }
  }
  .ng-select-bottom {
    top: 12px !important;
  }
}
