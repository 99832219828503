.shoot {

    &__status {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: #FFFFFF;

        padding: .6rem 1.2rem;
        box-sizing: border-box;
        border-radius: 4px;

        &--orange {
            background: #FFBF60;
            border: 2px solid #FFBF60;    
        }
    }

    &__card {
        height: 36rem;
    }
}
