.time-table {

  &__header {
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 3px 3px 0 0;
    display: flex;
  }

  &__header-label {
    padding: 12px 24px;
    text-align: center;
    box-sizing: border-box;
    min-width: 96px;
  }

  &__studio-container {
    display: flex;
    width: 100%;
  }

  &__studio {
    width: 50%;
    padding: 12px 24px;

    &--full {
      border-left: 1px solid #E0E0E0;
      width: 100%;
      text-align: center;
    }

    &:nth-child(2) {
      border-right: 1px solid #E0E0E0;
    }
  }

  &__scroll {
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid #E0E0E0;

    &:not(:last-child) {
      border-right: 1px solid #E0E0E0;
    }
  }

  &__scroll-button {
    margin: 0 !important;
  }

  &__icon {
    margin: 0 !important;
  }


  &__table {
    width: 100%;
    height: calc(100vh - 31rem);
    display: flex;
    overflow-y: auto;
    border-bottom: 1px solid #E0E0E0;
    border-right: 1px solid #E0E0E0;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__data {
    width: 100%;
    height: 100%;
    display: flex;
  }

  &__data-row {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: max-content;
    position: relative;

    &--full {
      width: 100%;
    }

    &:not(:last-child) {
      border-right: 1px solid #E0E0E0;
    }
  }

  &__cell {
    box-sizing: border-box;
    min-height: 48px;
    width: 100%;
    text-align: end;

    &:nth-child(odd) {
      background: #FAFAFA;
    }
  }

  &__record {
    position: absolute;
    overflow: hidden;
    width: calc(100% - 12px);
    left: 6px;
    background: white;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 12px;
    box-sizing: border-box;
  }

  &__record-heading {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #263238;
  }


  &__time-container {
    display: flex;
    flex-direction: column;
  }

  &__time-row {
    border: 1px solid #E0E0E0;
    border-bottom: none;
    width: 98px;
    min-height: 96px;
    box-sizing: border-box;
    padding: 12px 0;
    text-align: center;

    &:first-child {
      border-top: none;
    }
  }

  @media (max-width: 1375px) {
    &__table {
      height: calc(100vh - 38.2rem);
    }
  }

  @media (max-width: 1025px) {
    &__table {
      height: calc(100vh - 45.8rem);
    }
  }
}
