.photo {
  width: 25px !important;
  height: 25px !important;
  border-radius: 100% !important;
  background: rgba(183, 183, 183, 0.5) !important;
  font-size: 10px !important;
  line-height: 25px !important;
  text-align: center !important;
  color: var(--primary) !important;

  &--input {
    width: 18px !important;
    height: 18px !important;
    font-size: 18px !important;
    padding: 0 !important;
    margin-right: 6px !important;
  }

  &--form {
    border-radius: 100% !important;
    background: rgba(183, 183, 183, 0.5) !important;
    width: 2.4rem !important;
    height: 2.4rem !important;
    padding: 0 !important;
    margin-right: .8rem !important;
    font-family: Roboto, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #263238;
  }

  &--comment {
    width: 32px !important;
    height: 32px !important;
    font-size: 14px !important;
    line-height: 24px !important;
    padding: 4px !important;
    margin-top: 4px !important;
    display: block !important;
  }
}
