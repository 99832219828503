.heading {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    color: #263238;
    letter-spacing: 0.02em;

    &--main {
        font-size: 28px !important;
        line-height: 120% !important;
    }

    &--section {
        font-size: 20px;
        line-height: 24px;
    }

    &--sub-section {
        font-size: 18px;
        line-height: 22px;
    }

}
