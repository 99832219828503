.board {
  display: flex;
  height: calc(100vh - 23.4rem);
  overflow-y: hidden;
  overflow-x: auto;

  &__col {
    height: 100%;
    width: 275px;
    min-width: 275px;
    background: #FAFAFA;
    border-radius: 4px;
    padding: 24px 12px;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      margin-right: 24px;
    }

  }

  &__col-heading {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    align-items: center;
    letter-spacing: 0.02em;
    color: #263238;
  }

  &__item-container {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    &--dragged {
      background: #D4D4D4;
    }
  }

  &__item {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    padding: 12px;
    box-sizing: border-box;
    cursor: pointer;
    margin-top: 24px;
  }

  &__item-heading {
    font-size: 14px;
    line-height: 24px;
    color: #263238;
  }


  &__item-bottom-container {
    display: flex;
    align-items: center;
    margin-top: 14px;
  }

  &__item-photo {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    box-sizing: border-box;
    background: rgba(74, 20, 140, .1);
    margin-right: 9px;

    &--empty {
      padding: 9.5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &--icon {
      font-size: 12px;
      height: 12px;
      width: 12px;
    }
  }

  &__item-date {
    font-size: 12px;
    line-height: 24px;
    color: #263238;
    opacity: 0.5;
  }

  &__item-priority {
    font-size: 12px;
    height: 12px;
    width: 12px;
    margin-right: 9px;
    line-height: 26px;

    &--high {
      color: #EB5757;
    }

    &--low {
      color: #B7B7B7;
    }
  }
}
