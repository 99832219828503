.items-container {
  display: flex !important;
  flex-direction: column !important;
  overflow-y: auto !important;
  max-height: 200px !important;
  padding-right: 6px !important;
  position: relative !important;

  &__leads {
    max-height: 225px !important;
  }

  &__empty-label {
    font-size: 16px !important;
    line-height: 18px !important;
    color: #263238 !important;
    opacity: 0.5 !important;
  }

  &__card {
    width: 100% !important;
    padding: 12px !important;
    border: 1px solid #E0E0E0 !important;
    box-sizing: border-box !important;
    border-radius: 4px !important;
    position: relative !important;

    &--label {
      font-size: 10px;;
      font-weight: 400;
      line-height: 9px;
    }

    &--filed {
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
    }

    &--grid {
      display: grid;
      grid-template-rows: repeat(2, 1fr);
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;

      &-box {

      }
    }

    &--skeleton {
      background: #D4D4D4 !important;
      height: 84px !important;
      overflow-y: hidden !important;
      @include skeleton-preloader;
    }

    &:hover {
      cursor: pointer;
    }

    &:not(:last-child) {
      margin-bottom: 12px !important;
    }

    &--active {
      border: 1px solid #4A148C !important;
    }

    &--disabled {
      opacity: .7;
      cursor: not-allowed !important;
    }

  }

  &__card-heading {
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    letter-spacing: 0.02em !important;
    color: #263238 !important;

    &--skeleton {
      height: 14px !important;
      width: 100% !important;
      background: #BEBEBE !important;
      border-radius: 4px !important;

      &:not(:last-child) {
        margin-bottom: 24px !important;
      }
    }
  }
}
