.m {
  &-0 {
    margin: 0 !important;
  }

  &-1 {
    margin: 6px !important;
  }

  &-2 {
    margin: 12px !important;
  }

  &-3 {
    margin: 24px !important;
  }

  &-4 {
    margin: 36px !important;
  }
}

.mx {
  &-1 {
    margin-right: 6px !important;
    margin-left: 6px !important;
  }

  &-2 {
    margin-right: 12px !important;
    margin-left: 12px !important;
  }

  &-3 {
    margin-right: 24px !important;
    margin-left: 24px !important;
  }

  &-4 {
    margin-left: 36px !important;
    margin-right: 36px !important;
  }
}

.my {
  &-1 {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }
  &-2 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }

  &-3 {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }

  &-4 {
    margin-top: 36px !important;
    margin-bottom: 36px !important;
  }
}

.mt {
  &-1 {
    margin-top: 6px !important;
  }
  &-2 {
    margin-top: 12px !important;
  }

  &-3 {
    margin-top: 24px !important;
  }

  &-4 {
    margin-top: 36px !important;
  }
}

.mb {
  &-0 {
    margin-bottom: 0 !important;
  }
  &-1 {
    margin-bottom: 6px !important;
  }
  &-2 {
    margin-bottom: 12px !important;
  }

  &-3 {
    margin-bottom: 24px !important;
  }

  &-4 {
    margin-bottom: 36px !important;
  }
}

.ml {
  &-0 {
    margin-left: 0 !important;
  }
  &-1 {
    margin-left: 6px !important;
  }
  &-2 {
    margin-left: 12px !important;
  }

  &-3 {
    margin-left: 24px !important;
  }

  &-4 {
    margin-left: 36px !important;
  }
}

.mr {
  &-1 {
    margin-right: 6px !important;
  }
  &-2 {
    margin-right: 12px !important;
  }

  &-3 {
    margin-right: 24px !important;
  }

  &-4 {
    margin-right: 36px !important;
  }
}

.p {
  &-0 {
    padding: 0px !important;
  }
  &-1 {
    padding: 6px !important;
  }
  &-2 {
    padding: 12px !important;
  }
  &-3 {
    padding: 24px !important;
  }
  &-4 {
    padding: 36px !important;
  }

  &-5 {
    padding: 48px !important;
  }
}

.px {
  &-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  &-1 {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }
  &-2 {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  &-3 {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  &-4 {
    padding-left: 36px !important;
    padding-right: 36px !important;
  }

  &-5 {
    padding-left: 48px !important;
    padding-right: 48px !important;
  }
}

.py {
  &-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  &-1 {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }

  &-2 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }

  &-3 {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }

  &-4 {
    padding-top: 36px !important;
    padding-bottom: 36px !important;
  }

  &-5 {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }
}

.pt {
  &-0 {
    padding-top: 0 !important;
  }

  &-1 {
    padding-top: 6px !important;
  }

  &-2 {
    padding-top: 12px !important;
  }

  &-3 {
    padding-top: 24px !important;
  }

  &-4 {
    padding-top: 36px !important;
  }

  &-5 {
    padding-top: 48px !important;
  }
}

.pb {
  &-0 {
    padding-bottom: 0 !important;
  }
  &-1 {
    padding-bottom: 6px !important;
  }
  &-2 {
    padding-bottom: 12px !important;
  }

  &-3 {
    padding-bottom: 24px !important;
  }

  &-4 {
    padding-bottom: 36px !important;
  }

  &-5 {
    padding-bottom: 48px !important;
  }
}

.pl {
  &-0 {
    padding-left: 0 !important;
  }
  &-1 {
    padding-left: 6px !important;
  }
  &-2 {
    padding-left: 12px !important;
  }

  &-3 {
    padding-left: 24px !important;
  }

  &-4 {
    padding-left: 36px !important;
  }

  &-5 {
    padding-left: 48px !important;
  }
}

.pr {
  &-0 {
    padding-right: 0 !important;
  }

  &-1 {
    padding-right: 6px !important;
  }

  &-2 {
    padding-right: 12px !important;
  }

  &-3 {
    padding-right: 24px !important;
  }

  &-4 {
    padding-right: 36px !important;
  }

  &-5 {
    padding-right: 48px !important;
  }
}
